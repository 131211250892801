define("portfolio/pods/components/logo/g-mail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sSGm0NbL",
    "block": "[[[11,\"img\"],[24,\"data-role\",\"icon\"],[24,\"alt\",\"Gmail\"],[24,\"data-icon\",\"gmail\"],[24,\"src\",\"assets/g-mail.svg\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "portfolio/pods/components/logo/g-mail/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});