define("portfolio/resolver", ["exports", "addon-checkpoint-resolver/resolver"], function (_exports, _resolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import Resolver from 'ember-resolver';
  var _default = _resolver.default;
  _exports.default = _default;
});