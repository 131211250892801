define("portfolio/pods/components/material-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hcfgXIT1",
    "block": "[[[11,\"material-card\"],[17,1],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[10,0],[14,\"data-role\",\"image\"],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[10,0],[14,\"data-role\",\"header\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[10,0],[14,\"data-role\",\"content\"],[12],[1,\"\\n        \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"    \"],[10,0],[14,\"data-role\",\"footer\"],[12],[1,\"\\n        \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"&image\",\"&header\",\"&content\",\"&footer\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "portfolio/pods/components/material-card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});