define("portfolio/pods/components/logo/node-js/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qsx0J3MZ",
    "block": "[[[11,\"img\"],[24,\"data-role\",\"icon\"],[24,\"alt\",\"Node.js\"],[24,\"data-icon\",\"node-js\"],[24,\"src\",\"assets/nodejs.png\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "portfolio/pods/components/logo/node-js/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});