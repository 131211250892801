define("portfolio/pods/components/image-blur/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pjhHwvGw",
    "block": "[[[11,\"image-blur\"],[17,1],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,2]],[14,\"alt\",\"blur\"],[12],[13],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,2]],[15,\"alt\",[30,3]],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@src\",\"@alt\"],false,[]]",
    "moduleName": "portfolio/pods/components/image-blur/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});