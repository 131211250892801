define("portfolio/pods/components/logo-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eook0GWm",
    "block": "[[[11,\"logo-grid\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "portfolio/pods/components/logo-grid/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});