define("portfolio/pods/components/logo/kuberneties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+h0XVCSq",
    "block": "[[[11,\"img\"],[24,\"data-role\",\"icon\"],[24,\"alt\",\"Kubernetes\"],[24,\"data-icon\",\"kubernetes\"],[24,\"src\",\"assets/kubernetes.svg\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "portfolio/pods/components/logo/kuberneties/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});