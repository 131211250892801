define("portfolio/pods/home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8K6BxiK",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[10,\"page\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[],false,[\"animated-background\",\"portfolio-card\"]]",
    "moduleName": "portfolio/pods/home/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});