define("portfolio/pods/components/logo/linked-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RoFkWFQU",
    "block": "[[[11,\"img\"],[24,\"data-role\",\"icon\"],[24,\"alt\",\"LinkedIn\"],[24,\"data-icon\",\"linked-in\"],[24,\"src\",\"assets/linkedin.svg\"],[17,1],[12],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "portfolio/pods/components/logo/linked-in/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});